// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"

import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

import "./controllers"
// import * as jquery_ujs from "jquery-ujs"
// import * as jQuery from "jquery"

// make bootstrap globally available
import * as bootstrap from "bootstrap"
window.bootstrap = bootstrap

// make jquery globally available
import jQuery from "jquery"
window.$ = window.jQuery = jQuery

// run main app
import {FrontendApp} from "./frontend/application"
window.app = FrontendApp.boot({ debug: document.querySelector("html")?.dataset.env == "development" })
import "./channels"
