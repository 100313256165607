import * as AppOS from "../appos"

const Component = class extends AppOS.Component {
  static name = "Flist"

  documentLoad() {
  }

  pageLoad() {
    document.querySelectorAll(`input[data-flist-q]`).forEach(qinput => {
      qinput.flist ||= new FlistInstance(qinput, this.opts)
    })

    document.querySelectorAll(`[data-flist-set-q]`).forEach(setter => {
      setter.addEventListener("click", ev => {
        ev.preventDefault()
        const td = ev.currentTarget.dataset
        document.querySelector(`input[data-flist-q="${td.flistSetQ}"]`)?.flist?.setQuery(td.flistQt)
      })
    })
  }
}

class FlistInstance {
  constructor(qinput, opts = {}) {
    this.qinput = qinput
    this.id = qinput.dataset.flistQ
    this.containers = document.querySelectorAll(`[data-flist="${this.id}"]`)

    this.opts = Object.assign({}, {
      reflectLocationHash: true,
      showErrors: true,
      linkOtherInputs: true,
    }, opts)

    this.qinput.addEventListener('keyup', event => this.qinputChangeThrottle(event))

    if(this.opts.reflectLocationHash) {
      this.applyQueryFromHash()
    }
  }

  applyQueryFromHash() {
    const hparams = AppOS.Util.getHashParams()
    const iq = hparams[`q-${this.id}`]?.trim()
    if(iq && iq != "") { this.setQuery(iq) }
  }

  setQuery(q, sendUpdate = true) {
    this.qinput.value = q
    if(sendUpdate) { this.qinputChangeThrottle() }
    return this
  }

  async applyHashFromQinput() {
    let obj = {}
    const query = this.qinput.value.trim()
    obj[`q-${this.id}`] = query == "" ? undefined : query
    AppOS.Util.updateHashParams(obj)
  }

  qinputChangeThrottle(event, suppressHashChange = false) {
    clearTimeout(this._qinputChangeThrottle)
    const query = this.qinput.value.trim()
    this._qinputChangeThrottle = setTimeout(_ => { this.filterList(query, suppressHashChange) }, 75)
    return true
  }

  stringToRegexp(q) {
    if(q == "") return null

    try {
      this.qinput.classList.remove("border", "border-danger")
      bootstrap.Tooltip.getInstance(this.qinput)?.dispose()
      return new RegExp(q, 'gi')
    } catch(e) {
      this.qinput.classList.add("border", "border-danger")

      if(this.opts.showErrors) {
        bootstrap.Tooltip.getInstance(this.qinput)?.dispose()
        const tooltip = new bootstrap.Tooltip(this.qinput, { trigger: "manual", title: e.message })
        // tooltip.setContent({ '.tooltip-inner': 'another title' })
        tooltip.show()
      }

      throw(e)
    }
  }

  async filterList(q, suppressHashChange = false) {
    if(this._filtering) { return this.qinputChangeThrottle(null, suppressHashChange) }
    this.qinput.classList.remove("border", "border-danger")
    bootstrap.Tooltip.getInstance(this.qinput)?.dispose()
    if(this._lastFiltered == q) { return }

    this._filtering = true
    try {
      const regex = this.stringToRegexp(q)

      const ctns = []
      this.containers.forEach(ctn => {
        ctns.push(this.filterContainer(ctn, regex))
      })
      await Promise.all(ctns)

      if(this.opts.reflectLocationHash && !suppressHashChange) {
        await this.applyHashFromQinput()
      }

      if(this.opts.linkOtherInputs) {
        document.querySelectorAll(`input[data-flist-q="${this.id}"]`).forEach(qinput => {
          if(qinput == this.qinput) return
          qinput.value = q
        })
      }

      //AppOS.Util.scrollIntoViewIfNeeded(this.qinput)
      //this.qinput.scrollIntoViewIfNeeded?.(true)
      // setTimeout(_ => this.qinput.scrollIntoViewIfNeeded?.(true), 1)
      setTimeout(_ => this.qinput.scrollIntoView({ behavior: "auto", block: "center", inline: "nearest"}), 1)

      this._lastFiltered = q
    } finally {
      this._filtering = false
    }
  }

  async filterContainer(ctn, regex) {
    let negatedTagEx = null
    let raw = null
    if (regex) {
      raw = regex.toString()
      if(raw.startsWith("/!~")) {
        const rawmatch = raw.match(/^\/!(.*?)\/([gimyus]*)$/)
        if (!rawmatch) { throw new Error('Invalid regex string') }
        negatedTagEx = new RegExp(rawmatch[1], rawmatch[2])
      }
    }
    ctn.querySelectorAll(`[data-flist-entry]`).forEach(rec => {
      const byT = rec.querySelector(`[data-flist-fby]`)
      if (regex === null) {
        rec.classList.remove("d-none")
        byT.textContent = byT.textContent.trim()
      } else {
        const val = byT.textContent.trim()
        if(val.match(regex) || (raw.startsWith("/~") && byT.dataset.flistTags?.match(regex)) || (negatedTagEx && !byT.dataset.flistTags?.match(negatedTagEx))) {
          rec.classList.remove("d-none")
          byT.innerHTML = val.replace(regex, '<mark class="highlight">$&</mark>')
        } else {
          rec.classList.add("d-none")
          byT.textContent = byT.textContent.trim()
        }
      }
    })
    $(ctn).trigger("flist:postFilter")
  }
}

AppOS.Application?.availableComponents?.push?.(Component)
export { Component }
