import * as AppOS from "../../appos"
import {TrigramIndex} from "../../lib/trigram_index"

const Component = class extends AppOS.Component {
  static name = "Sot__PatchNotesFilter"

  init() {
  }

  documentLoad() {
  }

  pageLoad() {
    $("[data-patch-note-filter]").removeClass("text-dark")
    $("[data-patch-note-filter]").on("keyup change", ev => {
      ev.preventDefault()
      if(ev.currentTarget.value === this.pendingQuery) return
      this.pendingQuery = ev.currentTarget.value
      clearTimeout(this.filterTimer)
      $(`[data-q-val="status"]`).html(`waiting`)
      this.filterTimer = setTimeout(_ => {
        $(`[data-q-val="status"]`).html(`searching`)
        $("[data-patch-note-filter]").addClass("text-dark")
        this.filterTimer = setTimeout(_ => this.filter(this.pendingQuery), 1)
      }, 500)
      return false
    }).keyup()
  }

  selector(add) {
    // this.selector = ".card-body > *:not(ul), .card-body > ul *"
    // this.selector = ", "
    // this.selector = "li"
    return [
      `.sot-changelog-markdown > p${add ?? ""}`,
      `.sot-changelog-markdown li${add ?? ""}`,
    ].join(", ")
  }

  index() {
    return new Promise((resolve, reject) => {
      this.indexer = new TrigramIndex()
      this.ii = 0

      $(this.selector()).each((i, el) => {
        $(el).attr("data-iiid", this.ii++)
        this.indexer.index(el.innerText, this.ii)
      })

      resolve(this.indexer)
    })
  }

  find(query, limit = null) {
    const filteredArray = this.indexer.find(query).map(r => {
      return r
    })
    // const filteredArray = this.items
    //   .map(item => {
    //     const itemLower = item.toLowerCase()
    //     const queryLower = query.toLowerCase()
    //     const distance = this.levenshteinDistance(itemLower, queryLower)
    //     const score = itemLower.indexOf(queryLower) === 0 ? -distance : distance
    //     return { item, score }
    //   })
    //   .filter(({ score }) => score <= query.length)
    //   .sort((a, b) => a.score - b.score)
    //   .map(({ item }) => item)

    if(limit !== null) {
      return filteredArray.slice(0, limit)
    }
    return filteredArray
  }

  findFirst(...args) {
    return find(...args)?.[0]
  }

  async filter(q) {
    const start = performance.now()

    // remove marks
    $(this.selector(" mark")).each(function() {
      $(this).replaceWith($(this).text());
    });

    // show auxilary hidden
    $(".aux-hidden").removeClass("d-none aux-hidden")

    // show or hide all
    if(q == "") {
      $(this.selector()).removeClass("d-none text-info")
      const ert = performance.now() - start
      $(`[data-q-val="ms"]`).html(`${ert.toFixed(1)}ms`)
      $(`[data-q-val="status"]`).html(``)
      $("[data-patch-note-filter]").removeClass("text-dark")
      $("details.patch-notes-card").attr("open", "open")
      return
    } else {
      $("details.patch-notes-card").attr("open", "open")
      $(this.selector()).addClass("d-none text-info")
    }

    // filter
    if(q.startsWith("*")) {
      q = q.substring(1)
      const regex = new RegExp(`(${q})`, 'gi')
      this.indexReady = this.index()
      this.indexReady.then(idx => {
        this.find(q).forEach(el => {
          if(el.matches < 3) return
          $(this.selector(`[data-iiid="${el.id}"]`)).removeClass("d-none").html(function(_, html) {
            return html.replace(regex, '<mark data-qmatch>$1</mark>')
          })
        })
      })
    } else {
      const regex = new RegExp(`(${q.toLowerCase()})`, 'gi')
      $(this.selector()).each((i, el) => {
        if(el.innerText.toLowerCase().match(regex)) {
          $(el).removeClass("d-none").html((_, html) => html.replace(regex, '<mark data-qmatch>$1</mark>'))
        } else {
        }
      })
    }

    // hide stuff without matches
    $("details.patch-notes-card").each((i, el) => {
      if($(el).find("mark[data-qmatch]").length) {
        // hide useless headers
        const els = []
        $(el).find(".sot-changelog-markdown > *").each((i, el) => {
          const tag = el.tagName.toLowerCase()

          //if(tag == "h1") return true
          if(tag == "hr") return true
          if(tag == "br") return true
          if(el.classList.contains("d-none")) return true
          if(tag == "ul" && !el.querySelectorAll("li:not(.d-none)").length) return true

          els.push([true, el, tag])
        })

        els.forEach(([a, el, tag], i) => {
          const hm = tag.match(/^h(\d)$/)
          if(hm) {
            // console.log("head", el)
          } else if (["ul"].includes(tag)) {
            // walk back and find headlines
            let hlevel = null
            let ni = i
            while(ni >= 0 && (!hlevel || hlevel > 1)) {
              const niel = els[ni]
              const hm = niel[2].match(/^h(\d)$/)
              if(hm) {
                const hml = parseInt(hm[1])
                if(!hlevel || hml < hlevel) {
                  hlevel = hml
                  niel[0] = false
                }
                // console.log("aye", ni, els[ni], hlevel, hm)
              } else if (["ul", "p"].includes(els[ni][2])) {
                // ignore
                niel[0] = false
              } else {
                console.log("unknown parent", ni, niel, hlevel)
                niel[0] = false
              }
              ni--
            }
          } else if (["a", "p"].includes(tag)) {
            els[i][0] = false
          } else {
            els[i][0] = false
            console.error(i, a, tag, el)
            alert("unknown element " + tag)
          }
        })

        // console.log(els)
        els.forEach(([a, el, tag], i) => {
          if(a) el.classList.add("d-none", "aux-hidden")
        })
      } else {
        // close cards without match
        $(el).removeAttr("open")
      }
    })

    const rt = performance.now() - start
    $(`[data-q-val="ms"]`).html(`${rt.toFixed(1)}ms`)
    $(`[data-q-val="status"]`).html(``)
    const qinput = $("[data-patch-note-filter]")
    qinput.removeClass("text-dark")
    console.log("filtered in", rt)
  }
}

AppOS.Application?.availableComponents?.push?.(Component)
export { Component }
