import { DirectUpload } from "@rails/activestorage"
import { Controller } from "@hotwired/stimulus"
import * as FilePond from "filepond"
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css"
import "filepond/dist/filepond.min.css"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"

FilePond.registerPlugin(FilePondPluginImagePreview)

export default class extends Controller {
  connect() {
    //$("html").on("drop", ev => { ev.preventDefault(); console.log(ev)})
    const inputElement = this.element.querySelector("input[type='file'].filepond")

    const filePondInstance = FilePond.create(inputElement, {
      credits: {},
      allowMultiple: true,
      instantUpload: false,
      allowBrowse: false,
      allowRevert: false,
      allowProcess: false,
      // dropOnPage: true,
      // storeAsFile: true,
      server: {
        process: (fieldName, file, metadata, load, error, progress, abort) => {
          // Here, handle file uploading using Active Storage's direct upload URL
          const upload = new DirectUpload(file, inputElement.dataset.directUploadUrl, {
            directUploadWillStoreFileWithXHR: (request) => {
              request.upload.addEventListener(
                "progress",
                event => progress(event.lengthComputable, event.loaded, event.total)
              )
            }
          })

          upload.create((error, blob) => {
            if (error) {
              error("File upload error.")
            } else {
              load(blob.signed_id)
            }
          })

          // Return a cleanup function
          return {
            abort: () => {
              file.cancel()
            },
          }
        },
      },
    })

    this.element.addEventListener("submit", ev => {
      ev.preventDefault()

      const files = filePondInstance.getFiles()
      let done = 0

      if (files.length > 0) {
        const submitBtn = ev.target.querySelector(`button[type="submit"]`)
        submitBtn.setAttribute("disabled", "disabled")
        submitBtn.innerHTML = `<div class="spinner-border spinner-border-sm text-warning"></div> <span>uploading images (${done}/${files.length})...<span>`
        const status = submitBtn.querySelector("span")

        filePondInstance.setOptions({
          allowDrop: false,
          allowPaste: false,
          allowRemove: false,
        })

        const uploadPromises = files.map(file => filePondInstance.processFile(file))
        uploadPromises.forEach(p => {
          p.then(_ => {
            done += 1
            if(done < uploadPromises.length) {
              status.innerText = `uploading images (${done}/${uploadPromises.length})...`
              console.log(done)
            }
          })
        })
        Promise.all(uploadPromises)
          .then(_ => {
            console.log("all done")
            submitBtn.innerHTML = `<div class="spinner-border spinner-border-sm text-warning"></div> please wait...`
            setTimeout(_ => {
              ev.target.submit()
            }, 100)
          })
          .catch(error => {
            alert("Error occurred, check console")
            console.error("Upload failed:", error)
            submitBtn.innerHTML = `Start Upload`
            submitBtn.removeAttribute("disabled")
          })
      } else {
        ev.target.submit()
      }
    })
  }
}
