import * as AppOS from "../appos/appos"

import "../appos/lib/poly/scrollIntoViewIfNeeded"

// import "../appos/component/example"
import "../appos/component/turbo_bridge"
import "../appos/component/keyboard"
import "../appos/component/bootstrap/turbospy"
import "../appos/component/bootstrap/tooltips"
import "../appos/component/bootstrap/responsive_details"
import "../appos/component/bootstrap/debug"
import "../appos/component/bootstrap/elect"
import "../appos/component/spotlight"
import "../appos/component/fake_details"
import "../appos/component/image_performance"
import "../appos/component/image_comparison_slider"
import "../appos/component/copy2clipboard"
import "../appos/component/ip_vis"
import "../appos/component/flist"
import "../appos/component/sot_world"
import "../appos/component/sot_player"
import "../appos/component/sot/flameheart_forever"
import "../appos/component/sot/item_finder"
import "../appos/component/sot/cannon_metrics"
import "../appos/component/sot/sail_redesign"
import "../appos/component/sot/ingame_time"
import "../appos/component/sot/refresh_timer"
import "../appos/component/sot/patch_notes_filter"
import "../appos/component/neighbour_planer"

import "../appos/component/hl_countdown"

import {GamesplanetCanvasLogo} from "./gamesplanet_canvas_logo"

class FrontendApp extends AppOS.Application {
  instance = "Frontend"

  init() {
    this.opts.Keyboard = {
      classModKeys: true,
    }
    this.constructor.availableComponents.forEach(c => this.use(c))

    if(this.components?.Spotlight?.opts) {
      this.components.Spotlight.opts.captureVoidSpace = false
    }

    if(this.components?.Keyboard) {
      this.input = this.components.Keyboard
    }
  }

  boot() {
  }

  documentLoad() {
    $(document).on("keydown", ev => {
      const t = $(`[data-search-hotkey-target]`)
      if(ev.keyCode == 70 && (ev.metaKey || ev.ctrlKey) && ev.altKey && t.length) {
        if(t.is("input")) {
          t.focus().select()
        } else {
          t.click()
        }
        ev.preventDefault()
      }
    })
  }

  pageLoad() {
    $("canvas.gamesplanet-logo").each((i, el) => {
      new GamesplanetCanvasLogo(el).animate()
    })
  }
}

export { FrontendApp }
