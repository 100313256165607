import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    src: String,
    interval: { type: Number, default: 5000 },
  }

  connect() {
    this.timeout = setTimeout(() => {
      this.element.parentElement.reload()
    }, this.intervalValue)
  }

  disconnect() {
    clearTimeout(this.timeout)
  }
}
